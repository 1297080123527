import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ApiNames from "./constants/ApiNames";
import AwarenessScreen from "./screens/AwarenessScreen";
import LandingScreen from "./screens/LandingScreen";
const LandingOTPScreen = React.lazy(() => import("./screens/LandingOTPScreen"));

const LandingRoutes = (props) => {
  const [token, setToken] = useState(null);
  const [loader, setLoader] = useState(true);
  const loaderStatus = async (status) => {
    setLoader(status);
  };

  useEffect(() => {
    initalizeToken();
  }, []);

  const initalizeToken = async () => {
    try {
      let data = {
        username: "hakunaMatata",
        password: "hakunaMatata",
      };
      let url = process.env.REACT_APP_BASEURL;
      if (window.location.protocol === "http:") {
        url = url.replace("https", "http");
      }
      let response = await fetch(`${url}${ApiNames.generateToken}`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      let jsonResponse = await response.json();
      if (jsonResponse.jwttoken) {
        setToken(jsonResponse.jwttoken);
      }
    } catch (error) {
      console.log("authenticateToken error :", error);
    }
  };
  return (
    <Routes>
      <Route
        path="/landing"
        element={
          <LandingScreen
            isLanding={true}
            loaderStatus={loaderStatus}
            token={token}
          />
        }
      />
      <Route
        path="/pin"
        element={<LandingOTPScreen loaderStatus={loaderStatus} token={token} />}
      />
      <Route path="*" element={<Navigate replace to={"/landing"} />} />

      <Route path="/awareness" element={<AwarenessScreen token={token} />} />
    </Routes>
  );
};

export default LandingRoutes;

const baseUrlConversion = () => {
  let baseUrl = process.env.REACT_APP_BASEURL;
  if (window.location.protocol === "http:") {
    baseUrl = baseUrl.replace("https", "http");
    return baseUrl;
  } else {
    return baseUrl;
  }
  // return "/";
};

export const fetchGet = async (endpoint, token) => {
  let baseUrl = baseUrlConversion();
  let stagingUrl = "http://65.108.62.253:8082";
  let response = await fetch(`${baseUrl}${endpoint}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  let jsonResponse = await response.json();
  return jsonResponse;
};

export const fetchPost = async (apiName, data, token) => {
  let baseUrl = baseUrlConversion();
  // let stagingUrl = "http://65.108.62.253:8082";
  let response = await fetch(`${baseUrl}${apiName}`, {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  let jsonResponse = await response.json();
  // console.log("RESPOnse json", jsonResponse);
  // console.warn("this response of post", jsonResponse);
  return jsonResponse;
};

//post form
export const fetchPost2 = async (apiName, formData, token) => {
  let response = await fetch(
    `${process.env.REACT_APP_BASEURL}${apiName}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      body: formData,
    }
    // body: JSON.stringify(data),\
  );
  let jsonResponse = await response.json();
  // console.warn("response of post ", jsonResponse);
  return jsonResponse;
};
export const fetchDelete = async (apiName, data, token) => {
  let baseUrl = baseUrlConversion();
  let response = await fetch(`${baseUrl}${apiName}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  let jsonResponse = await response.json();
  console.log("RESPOnse json", jsonResponse);
  // console.warn("this response of post", jsonResponse);
  return jsonResponse;
};

import React, { useEffect, useState } from "react";
import ApiNames from "../constants/ApiNames";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import tagManagerEvents from "../utils/GoogleTagManager";
import TagManager from "react-gtm-module";
import { fetchPost, fetchGet } from "../utils/FetchApi";
import Landing from "../components/Landing";
import { encode as base64_encode } from "base-64";
const LandingScreen = (props) => {
  const search = useLocation().search;

  const [phNumber, setphNumber] = useState("");
  const [error, setError] = useState({ Error: "", isTrue: false });
  const [searchParams, setSearchParams] = useSearchParams();
  const [heOtpFlag, setHeOtpFlag] = useState(false);

  const utm_source = searchParams.get("utm_source")
    ? searchParams.get("utm_source")
    : "";
  const parameters = searchParams.get("parameter")
    ? searchParams.get("parameter")
    : "";
    const queryParams = new URLSearchParams(search)
    const perameter = getParameterCaseInsensitive("p");

  const [miniLoading, setMiniLoading] = useState("false");
  const [userPackage, setUserPackage] = useState("");
  const [isModel,setIsModel]=useState(true)


  const navigate = useNavigate();

  useEffect(() => {
  
    if (props.token) {
      getFlow();
    }
  }, [props.token]);
  const initializeTagManager = () => {
    const tagManagerArgs = {
      gtmId: "GTM-5TH9GVH",
    };
    TagManager.initialize(tagManagerArgs);
  };
  // const initializeTagManager = () => {
  //   const tagManagerArgs = {
  //     gtmId: "GTM-5TH9GVH",
  //   };
  //   const script = document.createElement("script");
  //   script.src = `https://www.googletagmanager.com/gtm.js?id=${tagManagerArgs.gtmId}`;
  //   script.async = true;
  //   document.head.appendChild(script);
  // };

  const getFlow = async () => {
    try {
      var endpoint = `${ApiNames.getFLow}`;
      endpoint = endpoint + "?utm_source=" + utm_source;

      let data = await fetchGet(endpoint, props.token);
      if (data.status === 200) {
        setTimeout(() => {
          headerEnrichment(data);
        }, 2000);
      } else {
        if (props?.isLanding) {
          initializeTagManager();
        }
        console.log("SomeThing went wrong");
      }
    } catch (error) {
      if (props?.isLanding) {
        initializeTagManager();
      }
      console.warn("get flow error subscription", error);
    }
  };
  const headerEnrichment = (data) => {
    try {
      if (props?.isLanding) {
        initializeTagManager();
      }
      if (data.response.msisdn === "") {
        tagManagerEvents("Wifi_User", utm_source);
      } else {
        if(perameter==="1"){
          setTimeout(() => {
            setIsModel(false)
          }, 2000);
        }
        tagManagerEvents("Data_User", utm_source);
        setphNumber(data.response.msisdn);
        if (data.response.signIn) {
          subscribe(
            data.response.isSpecialFlow && data.response.isFastFlow
              ? ApiNames.subscribeFastFlow
              : ApiNames.subscribe,
            data.response.msisdn
          );
        } else {
          setHeOtpFlag(true);
        }
      }

      if (data.response.isSpecialFlow === false) {
        if (data.response.msisdn) {
          setphNumber(data.response.msisdn);
          setUserPackage(data.response.subPackage);
        }
      } else {
        if (data.response.msisdn && data.response.otp) {
          navigate("/pin", {
            state: {
              msisdn: data.response.msisdn,
              utm_source: utm_source,
              seconds: data.response.milliseconds,
              otp: data.response.otp,
              isSpecialFlow: data.response.isSpecialFlow,
              isFastFlow: data.response.fastFlow,
              parameters: parameters,
              userPackage: data.response.subPackage,
              isUserActive: false,
              HeFlag: null,
            },
          });
        }
      }
    } catch (error) {
      console.log("Something went wrong");
    }
  };
  const onChangeMobileNumber = (value) => {
    setError((prevState) => ({
      ...prevState,
      ["Error"]: "",
      ["isTrue"]: false,
    }));
    if (value === "0" || !value.startsWith("3")) {
      setphNumber("");
    } else {
      let numRegex = new RegExp("^[0-9]*$");
      if (numRegex.test(value)) {
        setphNumber(value);
      }
    }
  };

  const onClickProceed = () => {
    if (userPackage) {
      checkUserSubscription(userPackage);
    } else {
      checkOperator();
    }
  };

  const checkOperator = async () => {
    try {
      tagManagerEvents("Msisdn_Page", utm_source);
      setMiniLoading(true);
      let endpoint = `${ApiNames.checkOperator}?msisdn=${phNumber}`;
      let response = await fetchGet(endpoint, props.token);
      if (response.status === 200) {
        if (response.response.operator.toLowerCase() === "other") {
          setError((prevState) => ({
            ...prevState,
            ["Error"]:
              "Service is only available for Jazz customers, kindly enter a working Jazz number",
            ["isTrue"]: true,
          }));
          setMiniLoading(false);
        } else {
          checkUserSubscription(response.response.package);
        }
      }
    } catch (error) {
      setMiniLoading(false);
      console.log("Proceed Error ::", error);
    }
  };

  const checkUserSubscription = async (userPackage) => {
    try {
      let response = await fetchGet(
        `${ApiNames.chekUser}?msisdn=${phNumber}`,
        props.token
      );
      if (response.status === 200) {
        if (
          response.status === 200 &&
          response.response.desc.toLowerCase() === "active"
        ) {
          sendWebOtp(phNumber, userPackage, true);
        } else {
          sendWebOtp(phNumber, userPackage, false);
        }
      } else {
        sendWebOtp(phNumber, userPackage, false);
      }
    } catch (error) {
      sendWebOtp(phNumber, userPackage, false);
    }
  };

  const sendWebOtp = async (phNumber, userPackage, isUserActive) => {
    try {
      setMiniLoading(true);
      let endpoint = isUserActive ? ApiNames.loginSendOtp : ApiNames.sendOtp;
      let response = await fetchGet(
        `${endpoint}?msisdn=${phNumber}`,
        props.token
      );
      if (response.status === 200 && response.response.result === 0) {
        setMiniLoading(false);
        tagManagerEvents("Request_OTP", utm_source);

        navigate("/pin", {
          state: {
            msisdn: phNumber,
            utm_source: utm_source,
            parameters: parameters,
            userPackage: userPackage,
            isUserActive: isUserActive,
            HeFlag: heOtpFlag ? response.response.desc : null,
          },
        });
      } else {
        setError((prevState) => ({
          ...prevState,
          ["Error"]: "Something went wrong, Please try again later",
          ["isTrue"]: true,
        }));
        setMiniLoading(false);
      }
    } catch (error) {
      setError((prevState) => ({
        ...prevState,
        ["Error"]: "Something went wrong, Please try again later",
        ["isTrue"]: true,
      }));
      setMiniLoading(false);
    }
  };

  const subscribe = async (subscribeApiName, _msisdn) => {
    try {
      setMiniLoading(true);
      let endpoint = `${subscribeApiName}?msisdn=${parseInt(
        _msisdn
      )}&level_id=1&name=Guest&utm_source=${utm_source}&subPackage=${userPackage}`;
      let response = await fetchPost(endpoint, null, props.token);
      if (response.status === 208) {
        let url = window.location.origin;
        let encodedMsisdn = base64_encode(_msisdn);
        let encodedSubId = base64_encode(response.response.subscribeId);
        if (parameters === "10k") {
          url = `${url}/home?ndsism=${encodedMsisdn}&subid=${encodedSubId}&parameter=${parameters}`;
        } else {
          url = `${url}/home?ndsism=${encodedMsisdn}&subid=${encodedSubId}`;
        }
        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
      } else {
        setMiniLoading(false);
        setError((prevState) => ({
          ...prevState,
          ["Error"]: "Something went wrong, Please try again later",
          ["isTrue"]: true,
        }));
        tagManagerEvents("Subcribe_Unsuccessful", utm_source);
      }
    } catch (error) {
      setMiniLoading(false);

      tagManagerEvents("Subcribe_Unsuccessful", utm_source);
      console.warn("subscribe error ", error);
    }
  };

  function getParameterCaseInsensitive(name) {
    for (const [key, value] of queryParams.entries()) {
      if (key.toLowerCase() === name.toLowerCase()) {
        return value;
      }
    }
    return null; // Return null if parameter is not found
  }

  const onHandleJoinNow=()=>{
    setIsModel(false)
  }

  return (
    <Landing
      mobileNumber={phNumber}
      onChangeMobileNumber={onChangeMobileNumber}
      onClickProceed={onClickProceed}
      miniLoading={miniLoading}
      error={error}
      parameters={parameters}
      // isModel={isModel}
      isModel={false}

      onHandleJoinNow={onHandleJoinNow}
    />
  );
};

export default LandingScreen;

import React from "react";
import MainImage from "../Assets/Images/awareness-main-img.webp";

const Awareness = () => {
  return (
    <>
      <div
        className="awareness-page"
        style={{ backgroundColor: "#ffd367", minHeight: "100vh" }}
      >
        <div className="image" style={{ maxWidth: "375px", margin: "auto" }}>
          <img
            src={MainImage}
            width={340}
            height={598}
            alt="awareness main image"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </div>
    </>
  );
};

export default Awareness;

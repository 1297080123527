import React, { useEffect, useState } from "react";
import mainMobileFighure from "../Assets/Images/landingpages-webp/PARHO-LANDING-PAGE-without-bg.webp";
import "../scss/LandingSubscription.scss";
import discountLogo from "../Assets/Images/jazz-landing-logo.webp";
import image3gb from "../Assets/Images/landingpages-webp/three-gb-image.webp";

const Landing = (props) => {
  let parameter = props.parameters.toLowerCase();
  let mobileRef = null;
  useEffect(() => {
    console.log("Check paramter ", parameter);
  });
  return (
    <>
    {props.isModel ? (
        <div className="lp-content">
          <div className="c-div">
            <img
              fetchpriority="high"
              width="137"
              height="73"
              src={discountLogo}
              alt="logo"
            />
            <div className="title">
              {" "}
              Unlock the<span> Power of Knowledge</span> and <span>win Rewards</span>{" "}
              with <span>Jazz Parho!</span>
            </div>
            <button onClick={() => props.onHandleJoinNow()}>Join Now</button>
          </div>
        </div>
      ) : (
      <div className="main-content">
        <div class="images">
          <img
            className="landing-logo"
            width="137"
            height="73"
            srcSet={discountLogo}
            alt="logo"
          />
          <img
            width="375"
            height="390"
            srcSet={parameter === "3gb" ? image3gb : mainMobileFighure}
            alt="Landing Image"
          />
        </div>

        <div className="landing_subscription_form">
          <div className="enter-otp">
          Enter your mobile number to receive an OTP.
          </div>
          <div className="number_taken_inputcol">
            <input
              type="hidden"
              name="_token"
              value="qivY7HzItF4WJ4F5O9pJdB1nV320LEbPD4CMwwEs"
            />{" "}
            <div className="number-fields">
              <div className="number_formate_bg b-shadow">+92</div>
              <div className="number_taken_inputcol">
                <input
                  className="form-control b-shadow"
                  ref={(ref) => {
                    mobileRef = ref;
                  }}
                  maxlength={10}
                  value={props.mobileNumber}
                  pattern="[0|3]?3[0-9]{2}([0-9])(?!\1{6})[0-9]{6}"
                  placeholder="3xx xxxx xxx"
                  required=""
                  autocomplete="off"
                  type="tel"
                  onChange={(val) =>
                    props.onChangeMobileNumber(val.target.value)
                  }
                />
              </div>
            </div>
            <div className="Resend-Container">
              <h1>{props.error.isTrue}</h1>
              {props.error.isTrue ? (
                <small className="error-heading">{props.error.Error}</small>
              ) : (
                ""
              )}
            </div>
            <p>
              <strong>
                <span class="server-response otp_instructions"></span>
              </strong>
            </p>
            {props.miniLoading === true ? (
              <button className="get-otp mb-block">
                <div
                  className="spinner-border text-white spinner-border-sm m-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </button>
            ) : (
              <button
                disabled={props.mobileNumber.length === 10 ? false : true}
                className="get-otp mb-block"
                type="submit"
                onClick={props.onClickProceed}
              >
                Get OTP
              </button>
            )}
            <p className="p-pricing" id="p-price-heading">
              <span>*</span> Only for Rs. 6+Tax/Day (Prepaid) & Rs. 35.86+Tax/Month (Postpaid)<span>*</span>
            </p>
            <div className="links">
              <a href="/privacypolicy" target="_blank">
                Privary Policy
              </a>
              <span></span>
              <a href="/terms" target="_blank">
                Terms &amp; Conditions
              </a>
            </div>
          </div>
        </div>
      </div>)}
    </>
  );
};

export default Landing;

import React from "react";
import ReactDOM from "react-dom/client";
import LandingRouting from "./LandingRoutes";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { BrowserRouter as Router } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));
const App = React.lazy(() => import("./App"));
root.render(
  <>
    <Router>
      {window.location.pathname.includes("/awareness") ||
      (window.location.pathname.includes("/landing") &&
        !localStorage.getItem("msisdn")) ? (
        <>
          <LandingRouting />
        </>
      ) : (
        <>
          <App />
        </>
      )}
    </Router>
  </>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
